<nav
  id="home-header"
  [ngClass]="{ 'header-fixed': isFixed }"
  class="navbar navbar-expand-lg text-16"
>
  <div class="container">
    <div class="d-flex justify-content-between flex-grow-1 mobile_menu">
      <a
        class="navbar-brand-two d-flex justify-content-between flex-grow-1"
        routerLink="/"
      >
        <img
          id="logo-image"
          src="assets/images/landing/lOGO_d/logo_white.png"
          alt="logo"
        />
      </a>
      <div class="text-end">
        <button
          class="navbar-toggler"
          type="button"
          (click)="isCollapsed = !isCollapsed"
          [attr.aria-expanded]="!isCollapsed"
          aria-controls="collapseExample"
        >
          <i
            class="eva eva-menu-outline text-30 text-white d-none d-md-block"
          ></i>
          <span class="navbar-toggler-icon d-block d-md-none"></span>
          <span class="navbar-toggler-icon d-block d-md-none"></span>
          <span class="navbar-toggler-icon d-block d-md-none"></span>
        </button>
      </div>
    </div>
    <div
      class="collapse navbar-collapse flex-grow-1 text-end"
      id="collapseExample"
      [ngbCollapse]="isCollapsed"
    >
      <ul class="navbar-nav ms-auto flex-nowrap">
        <li class="nav-item" (click)="navigateAndScroll('/', 'intro-wrap')">
          <a class="nav-link m-2 menu-item nav-active">Home</a>
        </li>
        <li class="nav-item" (click)="navigateAndScroll('/', 'services-wrap')">
          <a class="nav-link m-2 menu-item">Services</a>
        </li>
        <li class="nav-item" (click)="navigateAndScroll('/', 'pricing-wrap')">
          <a class="nav-link m-2 menu-item">Pricing</a>
        </li>
        <li class="nav-item" (click)="navigateAndScroll('/', 'contacts-wrap')">
          <a class="nav-link m-2 menu-item">Contact Us</a>
        </li>
        <li *ngIf="!isLoggedIn" class="nav-item" (click)="openLogin()">
          <a class="nav-link m-2 menu-item">Log In | Sign Up</a>
        </li>
        <li *ngIf="isLoggedIn" class="nav-item" (click)="openDashboard()">
          <a class="nav-link m-2 menu-item">Dashboard</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
