<div class="dashboard-layout">
  <aside class="dashboard-sidebar" [class.collapsed]="isSidebarCollapsed">
    <app-dashboard-sidebar
      (toggleSidebarEvent)="onToggleSidebar($event)"
    ></app-dashboard-sidebar>
  </aside>
  <div class="main-area">
    <header class="dashboard-header">
      <app-dashboard-header></app-dashboard-header>
    </header>
    <main class="main-content">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
