<section class="footer_wrap" id="footer-wrap">
  <div class="container">
    <div class="row">
      <div class="col-md-6 mb-4">
        <div class="o-hidden text-start">
          <div class="">
            <h4 class="card-title fw-bold">Startly - Find Fun for Your Kids</h4>
            <p class="lead">
              Startly helps parents discover amazing local activities and events
              for their kids. Find everything from sports camps and coding
              classes to art workshops - all in one place.
            </p>
            <!-- <a href="#" class="btn btn-primary mt-3">Explore Activities Now</a> -->
          </div>
        </div>
      </div>

      <div class="col-md-3 mb-4 mb-lg-0 mb-md-0">
        <div class="o-hidden text-start">
          <div class="">
            <h4 class="card-title fw-bold">Resources</h4>
            <ul class="list-group list-unstyled">
              <li class="">
                <a href="/locations">View All Locations</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-md-3 mb-4 mb-lg-0 mb-md-0">
        <div class="o-hidden text-start">
          <div class="">
            <h4 class="card-title fw-bold">Contact Info</h4>
            <div class="address-wrap mb-3">
              <ul class="list-group list-unstyled">
                <li class="">
                  <i class="eva eva-pin-outline me-2"></i> Startly.io, San Jose,
                  CA
                </li>
              </ul>
            </div>
            <div class="address-wrap mb-3">
              <ul class="list-group list-unstyled">
                <li class="">
                  <i class="eva eva-email-outline me-2"></i>
                  <a href="mailto:support@startly.io">support&#64;startly.io</a>
                </li>
              </ul>
            </div>

            <!-- <div class="address-wrap mb-3">
              <ul class="list-group list-unstyled">
                <li class="">
                  <i class="eva eva-phone-call-outline me-2"></i> +1234567890
                </li>
              </ul>
            </div> -->
            <!-- 
            <section class="social-wrap">
              <div class="social-btns-wrap">
                <a class="social-btn text-30 facebook ms-0" href="#"
                  ><i class="eva eva-facebook"></i
                ></a>

                <a class="social-btn text-30 twitter" href="#"
                  ><i class="eva eva-twitter"></i
                ></a>

                <a class="social-btn text-30 google" href="#"
                  ><i class="eva eva-google"></i
                ></a>
              </div>
            </section> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
