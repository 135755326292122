import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
})
export class LoadingComponent implements OnInit {
  loading = true;
  message: string | null = null;

  constructor(private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = false; // Update the loading state
      this.router.navigate([`/app/overview`]); // Navigate after the delay
    }, 2000); // 2000 ms = 2 seconds
  }

  onSelectOrg(orgId: string | null): void {
    this.router.navigate([`/app/overview`]);
  }
}
