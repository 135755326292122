<div class="container">
  <h1>Find Locations</h1>

  <input
    type="text"
    placeholder="Search for a state"
    [(ngModel)]="searchText"
    (ngModelChange)="onSearchChange()"
  />

  <ul>
    <li *ngFor="let state of filteredStates" (click)="onStateClick(state.slug)">
      {{ state.name }}
    </li>
  </ul>
</div>
