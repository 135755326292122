import { Component } from "@angular/core";
import { Router } from "@angular/router";

interface State {
  name: string;
  slug: string;
}

@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.scss"],
})
export class LocationsComponent {
  states: State[] = [
    { name: "Alabama", slug: "al" },
    { name: "Alaska", slug: "ak" },
    { name: "Arizona", slug: "az" },
    { name: "Arkansas", slug: "ar" },
    { name: "California", slug: "ca" },
    { name: "Colorado", slug: "co" },
    { name: "Connecticut", slug: "ct" },
    { name: "Delaware", slug: "de" },
    { name: "Florida", slug: "fl" },
    { name: "Georgia", slug: "ga" },
    { name: "Hawaii", slug: "hi" },
    { name: "Idaho", slug: "id" },
    { name: "Illinois", slug: "il" },
    { name: "Indiana", slug: "in" },
    { name: "Iowa", slug: "ia" },
    { name: "Kansas", slug: "ks" },
    { name: "Kentucky", slug: "ky" },
    { name: "Louisiana", slug: "la" },
    { name: "Maine", slug: "me" },
    { name: "Maryland", slug: "md" },
    { name: "Massachusetts", slug: "ma" },
    { name: "Michigan", slug: "mi" },
    { name: "Minnesota", slug: "mn" },
    { name: "Mississippi", slug: "ms" },
    { name: "Missouri", slug: "mo" },
    { name: "Montana", slug: "mt" },
    { name: "Nebraska", slug: "ne" },
    { name: "Nevada", slug: "nv" },
    { name: "New Hampshire", slug: "nh" },
    { name: "New Jersey", slug: "nj" },
    { name: "New Mexico", slug: "nm" },
    { name: "New York", slug: "ny" },
    { name: "North Carolina", slug: "nc" },
    { name: "North Dakota", slug: "nd" },
    { name: "Ohio", slug: "oh" },
    { name: "Oklahoma", slug: "ok" },
    { name: "Oregon", slug: "or" },
    { name: "Pennsylvania", slug: "pa" },
    { name: "Rhode Island", slug: "ri" },
    { name: "South Carolina", slug: "sc" },
    { name: "South Dakota", slug: "sd" },
    { name: "Tennessee", slug: "tn" },
    { name: "Texas", slug: "tx" },
    { name: "Utah", slug: "ut" },
    { name: "Vermont", slug: "vt" },
    { name: "Virginia", slug: "va" },
    { name: "Washington", slug: "wa" },
    { name: "West Virginia", slug: "wv" },
    { name: "Wisconsin", slug: "wi" },
    { name: "Wyoming", slug: "wy" },
  ];
  filteredStates: State[] = [];
  searchText = "";

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.filteredStates = this.states;
  }

  onSearchChange(): void {
    this.filteredStates = this.states.filter((state) =>
      state.name.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  onStateClick(slug: string): void {
    console.log(slug);
    this.router.navigate(["/locations", slug]);
  }
}
