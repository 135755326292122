import { LandingLayoutComponent } from "./shared/components/layouts/landing-layout/landing-layout.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LocationsComponent } from "./views/landing/locations/locations.component";
import { DashboardLayoutComponent } from "./shared/components/layouts/dashboard-layout/dashboard-layout.component";
import { AuthGuard } from "./auth.guard";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { LoadingComponent } from "./views/dashboard/loading/loading.component";

const routes: Routes = [
  {
    path: "",
    component: LandingLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./views/landing/landing.module").then((m) => m.LandingModule),
      },
    ],
  },
  {
    path: "locations",
    pathMatch: "full",
    component: LandingLayoutComponent,
    children: [
      {
        path: "",
        component: LocationsComponent,
      },
    ],
  },
  {
    path: "locations",
    component: LandingLayoutComponent,
    loadChildren: () =>
      import("./statepages/statepages.module").then((m) => m.StatePagesModule),
  },
  {
    path: "locations",
    component: LandingLayoutComponent,
    loadChildren: () =>
      import("./citypages/citypages.module").then((m) => m.CityPagesModule),
  },
  {
    path: "",
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "app",
        loadChildren: () =>
          import("./views/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
    ],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "sessions",
        loadChildren: () =>
          import("./views/dashboard/sessions.module").then(
            (m) => m.SessionsModule
          ),
      },
    ],
  },
  { path: "loading", component: LoadingComponent, canActivate: [AuthGuard] },
  // Add a wildcard route to handle undefined paths and redirect to the root
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
