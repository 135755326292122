export const environment = {
  production: true,
  algolia: {
    appId: "40311Q0KN0",
    apiKey: "95b40915adbe35334372a6e117652ba2",
    indexName: "Startly_Free_Dev",
  },
  firebaseConfig: {
    apiKey: "AIzaSyDWuiun77MaFQxaRJxigj4enGX4ATn2lI0",
    authDomain: "startly-280323.firebaseapp.com",
    projectId: "startly-280323",
    storageBucket: "startly-280323.appspot.com",
    messagingSenderId: "1096337434484",
    appId: "1:1096337434484:web:c9287b91e6b121b7c0ae21",
    measurementId: "G-9KKMDX9HFJ",
  },
  API_BASE_URL: "https://startly-api-dev.gunveernatt.uk/api",
};
